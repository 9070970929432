















import './scss/BaseCardShell.scss';
import './scss/BaseCardShellAdaptive.scss';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    name: 'BaseCardShell',

    components: {
        BaseCheckbox: () => import('@components/BaseCheckbox'),
    },
})
export default class BaseCardShell extends Vue {
    @Prop({}) index!: number;
    @Prop({}) selected!: number;
    @Prop({}) checkboxDisabled!: boolean;


    emitSelectionChanged() {
        this.$emit('selection-changed');
    }

    get formattedIndex() {
        if (this.index < 9) return `0${this.index + 1}`
        return String(this.index + 1);
    }
}
